








import Vue from 'vue';
import People from '@/views/BasicInformation/People.vue';
import Places from '@/views/BasicInformation/Places.vue';
import Tools from '@/views/BasicInformation/Tools.vue';

export default Vue.extend({
  name: 'OperationalUnitItems',
  components: {
    People,
    Places,
    Tools,
  },
  methods: {
    textFiltering() {
      (this.$refs.people as any).textFiltering();
      (this.$refs.places as any).textFiltering();
      (this.$refs.tools as any).textFiltering();
    },
  },
});
