import { i18n } from '@/i18n';
import { Route } from '@/model/enums/route';
import { RedirectLink } from '@/model/redirect-link';

export const basicInformationPages: RedirectLink[] = [
  {
    name: i18n.t('basic-information.place.name'),
    icon: '/images/place.svg',
    location: Route.BASIC_INFORMATION_PLACES,
  },
  {
    name: i18n.t('basic-information.people.name'),
    icon: '/images/basic-info-name.svg',
    location: Route.BASIC_INFORMATION_PEOPLE,
  },
  {
    name: i18n.t('basic-information.tool.name'),
    icon: '/images/tool.svg',
    location: Route.BASIC_INFORMATION_TOOLS,
  },
];
