




























import Vue from 'vue';
import { basicInformationPages } from '@/constants/page/basic-information-pages';
import Menu from '@/model/menu';
import Place from '@/../public/images/place.svg?inline';
import Tool from '@/../public/images/tool.svg?inline';
import Person from '@/../public/images/basic-info-name.svg?inline';
import { RedirectLink } from '@/model/redirect-link';

export default Vue.extend({
  name: 'BasicInformationListButtons',
  components: {
    Place,
    Tool,
    Person,
  },
  data() {
    return {
      basicInformationPages: basicInformationPages as RedirectLink[],
    };
  },
  methods: {
    isPlace(page: Menu): boolean {
      return page.icon === '/images/place.svg';
    },
    isTool(page: Menu): boolean {
      return page.icon === '/images/tool.svg';
    },
  },
});
