

























import Vue from 'vue';
import TopMenu from '@/components/Menubar/TopMenu.vue';
import SearchBar from '@/components/Menubar/SearchBar.vue';
import BasicInformationListButtons from '@/components/BasicInformation/BasicInformationListButtons.vue';
import OperationalUnitItems from '@/components/BasicInformation/OperationalUnitItems.vue';
import { Route } from '@/model/enums/route';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'BasicInformation',
  components: {
    TopMenu,
    SearchBar,
    BasicInformationListButtons,
    OperationalUnitItems,
  },
  data() {
    return {
      searchText: '',
    };
  },
  computed: {
    ...mapGetters({ openedUnitName: 'operationalUnitStorage/getOpenedOperationalUnitName' }),
    routeName(): string {
      return Route.ORGANIZATIONAL_MAP;
    },
  },
  methods: {
    textFiltering(text: string) {
      this.setFilter(text);
      (this.$refs.component as any).textFiltering();
      this.$nextTick(() => this.$store.commit('filterStorage/setFiltering', false));
    },
    setFilter(text: string) {
      this.$store.commit('filterStorage/setBasicInformationSearchText', text);
      this.searchText = text;
    },
  },
});
